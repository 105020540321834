"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VACANCY_AUTH_STRATEGY_MAP = exports.VACANCY_STATUS_MAP = exports.CookieConfigConsentType = exports.ConsentType = exports.userHasPlaceholderEmail = exports.generateEmailPlaceholder = exports.isLoginOrigin = exports.LoginOrigin = void 0;
// eslint-disable-next-line no-shadow
var LoginOrigin;
(function (LoginOrigin) {
    LoginOrigin["landingPage"] = "landingPage";
    LoginOrigin["magicLink"] = "magicLink";
})(LoginOrigin = exports.LoginOrigin || (exports.LoginOrigin = {}));
const isLoginOrigin = (value) => {
    return Object.values(LoginOrigin).includes(String(value));
};
exports.isLoginOrigin = isLoginOrigin;
const generateEmailPlaceholder = (str) => {
    // remove all spaces and + signs from the string
    const sanitizedStr = str.split(' ').join('').replace(/\+/g, '');
    return `noreply+missing-email-for-candidate-${sanitizedStr}@harver.com`;
};
exports.generateEmailPlaceholder = generateEmailPlaceholder;
const userHasPlaceholderEmail = (user) => { var _a; return user.email === (0, exports.generateEmailPlaceholder)((_a = user.phone) !== null && _a !== void 0 ? _a : ''); };
exports.userHasPlaceholderEmail = userHasPlaceholderEmail;
// eslint-disable-next-line no-shadow
var ConsentType;
(function (ConsentType) {
    ConsentType["Custom"] = "custom";
    ConsentType["Default"] = "default";
    ConsentType["Cookie"] = "cookie";
    ConsentType["Proctoring"] = "proctoring";
})(ConsentType = exports.ConsentType || (exports.ConsentType = {}));
// eslint-disable-next-line no-shadow
var CookieConfigConsentType;
(function (CookieConfigConsentType) {
    CookieConfigConsentType["Mandatory"] = "mandatory";
    CookieConfigConsentType["Optional"] = "optional";
    CookieConfigConsentType["Hidden"] = "hidden";
})(CookieConfigConsentType = exports.CookieConfigConsentType || (exports.CookieConfigConsentType = {}));
exports.VACANCY_STATUS_MAP = { OPEN: 'OPEN', CLOSED: 'CLOSED', DRAFT: 'DRAFT' };
exports.VACANCY_AUTH_STRATEGY_MAP = {
    MAGIC_LINK: 'MAGIC_LINK',
    ATS_MAGIC_LINK: 'ATS_MAGIC_LINK',
    ATS_FIRST_REGISTRATION: 'ATS_FIRST_REGISTRATION',
};
