import { datadogRum } from '@datadog/browser-rum';
import { ContextValue, Datacenter } from '@datadog/browser-core';

const init = ({ clientToken, applicationId }) => {
    datadogRum.init({
        applicationId,
        clientToken,
        datacenter: Datacenter.US,
        allowedTracingUrls: [
            'https://api-journey.harver.com',
            'https://api-journey.harver.app',
            'https://api-journey.harver-test.com',
            'https://api-journey.harver-test.app',
            'https://api-journey.harver-acc.com',
            'https://api-journey.harver-acc.app',
        ],
        // NOTE: Connecting RUM and traces may significantly increase the APM-ingested volumes.
        // The traceSampleRate is set to keep 20% of backend traces.
        traceSampleRate: 20,
        sessionSampleRate: 10,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: false,
    });
};

const addGlobalContext = (key: string, value: ContextValue) => {
    datadogRum.setGlobalContextProperty(key, value);
};

export const realtimeUserMonitoringService = {
    init,
    addGlobalContext,
};
