"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.captureRuntimeArguments = void 0;
/**
 * Captures the arguments passed to a function at runtime and returns them. Don't try to destructure the result, it will not work.
 * This is because the return array (with the results) is passed by reference and not by value. Only access the properties by index
 * _and_ after the code has been called that supposedly is calling T[K] at some point. Then the array will be populated with
 * those arguments.
 * @param object Any object T
 * @param property Any property K of object T that you want to capture the arguments of. Must be a function.
 * @returns An array containing all the arguments in order that were used to call the function with.
 */
const captureRuntimeArguments = (object, property) => {
    const fn = object[property];
    if (typeof fn !== 'function') {
        throw new Error(`Property ${String(property)} is not a function`);
    }
    const capture = [];
    object[property] = ((...args) => {
        // Push the arguments into the array to be read out later (passed by reference)
        capture.push(...args);
        // Restore the original function
        object[property] = fn;
        // Call it with the original context and arguments
        return fn.apply(object, args);
    });
    return capture;
};
exports.captureRuntimeArguments = captureRuntimeArguments;
