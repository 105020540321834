"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidEmail = exports.LocaleDirection = void 0;
// eslint-disable-next-line no-shadow
var LocaleDirection;
(function (LocaleDirection) {
    LocaleDirection["RTL"] = "rtl";
    LocaleDirection["LTR"] = "ltr";
})(LocaleDirection = exports.LocaleDirection || (exports.LocaleDirection = {}));
// eslint-disable-next-line security/detect-unsafe-regex, no-empty-character-class
const emailRegExp = /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
/**
 * Validates an email address.
 *
 * @param {string} str - The email address to validate.
 * @param {boolean} [opts.simple] - If true, performs a simple check for the presence of '@' instead of full validation.
 * @returns {boolean} Returns `true` if the email is valid, otherwise `false`.
 */
const isValidEmail = (str, opts) => {
    if (!str)
        return false;
    if (opts === null || opts === void 0 ? void 0 : opts.simple)
        return str.indexOf('@') > -1;
    const [name, domain] = str.split('@');
    if (!name || !domain)
        return false;
    if (name.length > 64 || domain.length > 255)
        return false;
    return emailRegExp.test(str);
};
exports.isValidEmail = isValidEmail;
